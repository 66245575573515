import { useRouter } from "next/router";
import React, {useState} from "react";

export const NotificationsContext = new React.createContext();

const NotificationsProvider = ({ children }) => {
    const [notification, setNotification] = useState();
    const [originHref, setOriginHref] = useState();
    const router = useRouter();

    const showNotification = (payload) => {
        if (JSON.stringify(notification) !== JSON.stringify(payload)) {
            setNotification(payload);
            setTimeout(() => hideNotification(), 5000);
            setOriginHref(router.asPath);
        }
    }

    const hideNotification = () => setNotification(undefined);

    return (
        <NotificationsContext.Provider value={{
            notification,
            showNotification,
            hideNotification,
            originHref,
            router
        }}>
            {children}
        </NotificationsContext.Provider>
    )
}

export default NotificationsProvider;