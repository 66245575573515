export const acronyms = ['ACH','SVB','SFTP','FPB']

export const titlecase = (str) => {
    if (!str) return '';
    const regex = new RegExp(`(${acronyms.join('|')})`,"gi")
    return str.replace(/_/g," ").replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    ).replace(regex, (txt) => txt.toUpperCase());
};

export const sentencecase = (str) => {
    if (!str) return '';
    const regex = new RegExp(`(${acronyms.join('|')})`,"gi")
    return str.toLowerCase()
        .replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()})
        .replace(regex, (txt) => txt.toUpperCase());
};


export const uncamelcase = (str) => {
    if (!str) return '';
    return str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
};

export const unsnakecase = (str) => {
    if (!str) return '';
    return str.replace(/_/g," ");
};
export const snakeCase = string => {
    if (!string || typeof string !== "string") return '';
    return string ? string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_') : '';
};

export const kebabCase = string => {
    if (!string || typeof string !== "string") return '';
    return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('-');
};

export const lowercase = (str) => str ? str.toLowerCase() : undefined;
export const uppercase = (str) => str ? str.toUpperCase() : undefined;

export const nospace = (str) => str.replace(/\s/g, '');
export const implodePercentChar = str => str.replace(/[%]{2,}/g, "%");