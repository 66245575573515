import React, {createContext, useState} from "react";

const DialogContext = createContext();

const DialogProvider = (props) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogProps, setDialogProps] = useState({});

    const showDialog = (value) => {
        setDialogProps(value);
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogProps({});
        setDialogVisible(false);
    };

    return (
        <DialogContext.Provider value={{
            dialogProps,
            dialogVisible,
            showDialog,
            hideDialog,
        }}
        >
            {props.children}
        </DialogContext.Provider>
    );
};

export { DialogContext, DialogProvider };
