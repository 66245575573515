import {CUSTOMERS, DASHBOARD, INVOICES, REPORTS, SETTINGS, TRANSACTIONS} from "../constants/Permissions";
import {
    faMagnifyingGlass as IconSearch,
    faObjectsColumn as IconDashboard,
    faAddressBook as IconPhoneBook,
    faFileInvoiceDollar as IconAccountDocument,
    faArrowRightArrowLeft as IconTransfer,
    faBookOpenCover as IconBook,
    faCog as IconCog
} from '@fortawesome/pro-light-svg-icons'

import {
    faMagnifyingGlass as IconSearchBold,
    faObjectsColumn as IconDashboardBold,
    faAddressBook as IconPhoneBookBold,
    faFileInvoiceDollar as IconAccountDocumentBold,
    faArrowRightArrowLeft as IconTransferBold,
    faBookOpenCover as IconBookBold,
    faCog as IconCogBold
} from '@fortawesome/pro-regular-svg-icons'
import {isMacOS, isWindows} from "./browser";
import { trackClick } from "./segment";
export const Permissions = [
    {
        value: "dashboard",
        label: "Dashboard",
    },
    {
        value: "customers",
        label: "Customers",
    },
    {
        value: "invoices",
        label: "Invoices",
    },
    {
        value: "transactions",
        label: "Transactions",
    },
    {
        value: "reports",
        label: "Reports",
    },
    {
        value: "settings",
        label: "Settings",
    },
];

export const getLinks = (isDemo=false) => {
    return([
        {
            icon: IconSearch,
            activeIcon: IconSearchBold,
            label: "Search",
            tag: `${isWindows() ? 'Ctrl + ' : '\u2318'}K`,
            onClick: () => {
                const event = new KeyboardEvent('keydown', {
                    key: 'k',
                    code: 'KeyK',
                    metaKey: isMacOS(), // Set metaKey to true for Cmd key on macOS
                    ctrlKey: isWindows(), // Set ctrlKey to true for Cmd key on Windows/Linux
                });
                
                trackClick({section: 'search_component', element: 'search_menu_button', invoked_by: 'click', action: 'open'});
                document.dispatchEvent(event);

                // for mobile, where there's no keydown even
                const searchEvent = new Event("mobile_open_search");
                document.dispatchEvent(searchEvent);
            }
        },
        {
            icon: IconDashboard,
            activeIcon: IconDashboardBold,
            label: "Dashboard",
            href: "/",
            permission: DASHBOARD,
        },
        {
            icon: IconPhoneBook,
            activeIcon: IconPhoneBookBold,
            label: "Customers",
            href: "/customers",
            permission: CUSTOMERS,
            regExp: /^\/customers(.*?)/,
        },
        {
            icon: IconAccountDocument,
            activeIcon: IconAccountDocumentBold,
            label: "Invoices",
            href: "/invoices",
            permission: INVOICES,
            subMenu: [
                { label: 'Draft', href: '/invoices', regExp: /(\/invoices$)|(\/draft-invoices)/, exclude: /^\/settings(.*?)/},
                { label: 'Scheduled', href: '/invoices/scheduled', regExp: /(\/invoices\/scheduled(.*?))/},
                { label: 'Sent', href: '/invoices/sent', regExp: /(\/invoices\/sent)|(\/invoices(?!(\/scheduled|$))(.*)$)/},
            ],
            whitelistPaths: ['/invoices/[id]'],
            exclude: /^\/settings(.*?)/
        },
        {
            icon: IconTransfer,
            activeIcon: IconTransferBold,
            label: "Transactions",
            href: "/transactions",
            permission: TRANSACTIONS,
            regExp: /^\/transactions(.*?)/
        },
        {
            icon: IconBook,
            activeIcon: IconBookBold,
            label: "Reports",
            href: "/reports",
            permission: REPORTS,
            subMenu: [
                { label: 'Payouts', href: '/reports', regExp: /(\/reports?(\?payout_id=([0-9]+))$)|(\/reports$)/}, // url matches either '/reports' or 'reports?=payout_id=*'
                { label: 'Aging Balance', href: '/reports/aging-balance' },
            ]
        },
        {
            icon: IconCog,
            activeIcon: IconCogBold,
            label: "Settings",
            href: "/settings",
            permission: SETTINGS,
            regExp: /^\/settings(.*?)/,
            subMenu: [
                { label: 'Team & Account', href: '/settings', regExp: /(\/settings)?((\/notifications))/ },
                ... isDemo ? [] : [{ label: 'Payment & Payout', href: '/settings/payment-and-payout', regExp: /(\/settings\/payment-and-payout)?((\/payout))/ }],
                { label: 'Customers', href: '/settings/customers' },
                { label: 'Invoicing', href: '/settings/invoicing', regExp: /(\/settings\/invoicing)?((\/reminders)|(\/net-terms))/ },
                { label: 'Connections', href: '/settings/connections', regExp: /(\/settings\/connections)?((\/shopify(.*))|(\/quickbooks))/ },
                { label: 'Plans & Billing', href: '/settings/plans-and-billing', regExp: /(\/settings\/plans-and-billing)/ },
            ]
        },
    ])
}

export const filterLinksFromPermissions = (permissions,isDemo=false) => {
    let newLinks = getLinks(isDemo);
    if (!permissions || permissions.length === 0) {
        return newLinks;
    }
    if (!permissions.includes(DASHBOARD)) {
        newLinks = newLinks.filter((link) => link.label !== "Dashboard");
    }
    if (!permissions.includes(CUSTOMERS)) {
        newLinks = newLinks.filter((link) => link.label !== "Customers");
    }
    if (!permissions.includes(INVOICES)) {
        newLinks = newLinks.filter((link) => link.label !== "Invoices");
    }
    if (!permissions.includes(TRANSACTIONS)) {
        newLinks = newLinks.filter((link) => link.label !== "Transactions");
    }
    if (!permissions.includes(REPORTS)) {
        newLinks = newLinks.filter((link) => link.label !== "Reports");
    }
    if (!permissions.includes(SETTINGS)) {
        newLinks = newLinks.filter((link) => link.label !== "Settings");
    }
    return newLinks;
};

export const isCompanyShopifyDemo = ({company}) => company && company?.source === "shopify" && company?.is_demo;
export const isGoodsMerchant = (company) => company && company.is_goods_merchant;
export const isServiceMerchant = (company) => company && !company.is_goods_merchant;