import {Box, ResponsiveContext, ThemeContext} from "grommet";
import React from "react";
import NarrowHeading from "../NarrowHeading";
import {
    faArrowRight as IconArrowRight,
    faArrowLeft as IconArrowLeft
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "../FontAwesomeIcon";
import Head from "../Head";
import StreamlinedLogo from "../icons/StreamlinedLogo";
import Footer from "../Footer";
import Button from "../Button";
import { snakeCase } from "../../utils/string";

const ErrorLayout = ({
    pageTitle="Error",
    redirectUrl,
    redirectButtonText,
    reverse=false,
    heading="Oh no!",
    message,
    statusCode,
    buttonProps,
    maxWidth,
    headingProps={}
}) => {
    const errorMessage = message || (statusCode === 404 ?
        "The page you were looking for does not exist. Could we take you back home?" :
        "We're having trouble loading this page. Could you please try again later?");

    headingProps = {...headingProps, alignSelf: "center"};

    return (
        <Box>
            <Head title={pageTitle} description={errorMessage} />
            <ResponsiveContext.Consumer>
                {(size) => (
                    <Box>
                        <ThemeContext.Consumer>
                            {(theme) => (
                                <Box align="center" margin={{horizontal: size === "small" ? "medium" : 'xlarge', vertical: (size==="small" ? "large" : "medium")}}>
                                    <Box direction="row" gap="medium" alignSelf="center" margin={{bottom: (size==="small" ? "large" : "medium")}}>
                                        <StreamlinedLogo size="small" color={theme.dark ? "white" : "black"} />
                                    </Box>
                                    <Box
                                        width={{max: maxWidth || 'medium'}}
                                        margin={{vertical: "large"}}
                                    >
                                        <NarrowHeading 
                                            heading={heading} 
                                            paragraph={errorMessage} 
                                            paragraphProps={{textAlign: 'center'}}
                                            {...headingProps} />
                                        {redirectUrl &&
                                            (<Box align="center" margin={{ top: 'medium', bottom: 'large' }}>
                                                <Button
                                                    event={{section: 'error_page', element: 'redirect', description: snakeCase(redirectButtonText)}} 
                                                    href={redirectUrl}
                                                    icon={<FontAwesomeIcon SelectedIcon={!reverse ? IconArrowLeft : IconArrowRight} size="xsmall" />}
                                                    label={redirectButtonText}
                                                    reverse={reverse}
                                                    {...buttonProps}
                                                />
                                            </Box>)}
                                    </Box>
                                    <Footer align="center" />
                                </Box>
                            )}
                        </ThemeContext.Consumer>
                    </Box>
                )}
            </ResponsiveContext.Consumer>
        </Box>
    );
};

export default ErrorLayout;