import React from "react";
import {normalizeColor} from "grommet/utils";
import StatusIcon from "../components/StatusIcon";
import {Box} from "grommet";
import {
    faXmarkLarge as IconClose
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "../components/FontAwesomeIcon";
const theme = {
    name: 'Streamlined',
    rounding: 4,
    spacing: 24,
    defaultMode: 'light',
    global: {
        colors: {
            // Brand colors ========================================
            cinder: {
                light: '#242729',
                dark: '#181A1B'
            },
            invisible: 'transparent',
            tan: {
                light: '#FAF3ED',
                dark: '#FAF3ED'
            },
            lemon: {
                light: '#FFCF49',
                dark: '#D4AF47'
            },
            lemonade: {
                light: '#FABF1B',
                dark: '#FABF1B',
            },
            'summer-sky': {
                light: '#75D8F5',
                dark: '#6DBDD4'
            },
            'light-sky-blue': '#A3D8F4',
            magento: {
                light: '#9A69FF',
                dark: '#9A69FF'
            },
            'alice-blue': {
                light: '#D2EAFB',
                dark: '#5A5E60'
            },
            'smoke': "#F4F4F4",
            'black': '#000000',
            'billowing-clouds-weak': {
                light: '#D8DDE388',
                dark: '#AAAAAA44'
            },
            'billowing-clouds': {
                light: '#D8DDE3',
                dark: '#666666'
            },
            trout: {
                light: '#4E5153',
                dark: '#4E5153'
            },
            'alice-blue-light': {
                light: '#E9F5FD',
                dark: '#4E5153'
            },
            'alice-blue-xlight': {
                light: '#F9FCFE',
                dark: '#4E5153'
            },
            "green-weak": {
                light: '#48DC5B88',
                dark: '#48DC5BCC',
            },
            "green-strong": {
                light: '#23B535',
                dark: '#88E895'
            },
            // Partner colors,
            "shopify-green": {
                light: '#96BF48',
                dark: '#96BF48'
            },
            "quickbooks-green": {
                light: '#2B9F1B',
                dark: '#2B9F1B'
            },
            white: {
                light: '#FFFFFF',
                dark: '#FFFFFF'
            },
            // Status colors ========================================
            'status-ok': {
                light: '#48DC5B',
                dark: '#48DC5BCC'
            },
            'status-warning-1': {
                light: '#FFCA58',
                dark: '#FFCA58'
            },
            'status-warning-2': {
                light: '#FFAA15',
                dark: '#FFAA15'
            },
            'status-critical-1': {
                light: '#F96401',
                dark: '#F96401'
            },
            'status-critical-2': {
                light: '#E60000',
                dark: '#FF6666'
            },
            'status-critical': 'status-critical-2',
            'status-info': {
                light: '#95D1FF',
                dark: '#95D1FF' // TODO look at this
            },
            'status-info-heavy': {
                light: '#59B8FF',
                dark: '#59B8FF' // TODO look at this
            },
            'status-disabled': {
                light: '#EEEEEE',
                dark: '#AAAAAA'
            },
            'neutral': {
                light: '#000000',
                dark: '#FFFFFF'
            },
            'neutral-1': {
                light: '#222222',
                dark: '#EFEFEF'
            },
            'neutral-2': {
                light: '#444444',
                dark: '#E9E9E9'
            },
            'neutral-3': {
                light: '#666666',
                dark: '#CCCCCC'
            },
            'neutral-4': {
                light: '#888888',
                dark: '#AAAAAA'
            },
            'neutral-5': {
                light: '#AAAAAA',
                dark: '#888888'
            },
            'neutral-6': {
                light: '#CCCCCC',
                dark: '#666666'
            },
            'neutral-7': {
                light: '#E9E9E9',
                dark: '#444444'
            },
            'neutral-8': {
                light: '#EFEFEF',
                dark: '#222222'
            },
            'neutral-9': {
                light: '#FFFFFF',
                dark: '#000000'
            },
            //========= Badge colors
            'invoice-has-credit': {
                light: '#95E24A',
                dark: '#48DC5B66',
            },
            'invoice-closed': {
                light: '#C7EEA0',
                dark: '#48DC5B44',
            },
            'invoice-processing': {
                light: 'accent',
                dark: 'accent',
            },
            'invoice-not-yet-due': {
                light: 'accent',
                dark: '#FFFFFF22'
            },
            'invoice-overdue': {
                light: '#FDF5E4',
                dark: '#FFCA5866',
            },
            'invoice-overdue-30+': {
                light: '#FDEDC4',
                dark: '#FFAA1566',
            },
            'invoice-overdue-60+': {
                light: '#FFDCB2',
                dark: '#F9640166',
            },
            'invoice-overdue-90+': {
                light: '#FFCEAE',
                dark: '#FF000066',
            },
            'invoice-voided': {
                light: '#E7E7E7',
                dark: '#E7E7E788',
            },
            'type-single-invoice':{
                light: '#FDEDC4',
                dark: '#FFAA1566',
            },
            'type-recurring-invoice': {
                light: '#95E24A66',
                dark: '#48DC5B66',
            },
            'type-milestone-invoice': 'alice-blue',
            //======== Chart colors
            "barchart-primary": {
                light: "#159F2B",
                dark: "#159F2B"
            },
            "barchart-secondary": {
                light: "#D6ECBF",
                dark: "#D6ECBF"
            },
            "piechart-secondary": {
                light: "#EAEFFB",
                dark: "accent"
            },
            //========= Email colors
            "email-bg-primary": {
                light: "#FBF6F2",
                dark: "#FBF6F2"
            },
            "email-bg-warning": {
                light: "#FFEAE4",
                dark: "#FFEAE4CC"
            },
            "email-warning-badge" : {
                light: "#FFBAA5",
                dark: "#FFBAA5",
            },
            //========= Color definition here
            background: {
                light: '#FFFFFF',
                dark: '#181A1B'
            },
            foreground: {
                light: '#FFFFFF',
                dark: "#292A2C"
            },
            "primary-side-bar": {
                light: '#F9FCFE', // alice blue light,
                dark: "#292A2C" // foreground
            },
            brand: 'lemon',
            primary: 'lemon',
            secondary: 'summer-sky',
            "border-weak": 'billowing-clouds-weak',
            border: 'billowing-clouds',
            "border-strong": "neutral-6",
            "border-xstrong": "neutral-4",
            accent: 'alice-blue',
            cta: 'lemon',
            'cta-hover': 'lemonade',
            control: 'lemon',
            text: 'neutral',
            'text-weak': {
                light: 'neutral-4',
                dark: 'neutral-3'
            },
            'text-xweak': {
                light: 'neutral-5',
                dark: 'neutral-5'
            },
            'text-reverse': 'neutral-9',
            'text-highlight': {
                light: '#FFFF8F',
                dark: '#73733E'
            },
            'text-info': {
                light: '#86bce5',
                dark: '#a7cbe6'
            },
            focus: {
                light: 'magento',
                dark: '#9487E8'
            },
            active: {
                light: 'alice-blue',
                dark: 'alice-blue-xlight'
            },
            hover: {
                light: '#E3F1FC',
                dark: '#FFFFFF22'
            }
        },
        hoverIndicator: "hover",
        font: {
            family: 'Calibre, sans-serif',
            size: '18px',
            height: '24px',
            maxWidth: '432px',
        },
        borderSize: {
            xsmall: '1.5px',
            small: '2px',
            medium: '4px',
            large: '12px',
            xlarge: '24px'
        },
        input: {
            weight: 400,
            extend: ({size}) => {
                let paddingSizes = {
                    small: '4px 8px',
                    medium: '12px',
                    large: '12px',
                    xlarge: '12px'
                }
                return `padding: ${paddingSizes[size]};`
            }
        },
        control: {
            border: {
                color: 'border-strong',
            }
        },
        spacing: '24px',
        focus: {
            shadow: {
                color: '#00000000'
            },
            border: {
                color: 'focus'
            }
        },
        breakpoints: {
            small: {
                value: 768
            },
            medium: {
                value: 1408
            }
        },
        hover: {
            background: {
                color: 'hover'
            }
        },
        drop: {
            background: 'foreground',
            shadowSize: 'none',
            elevation: 'small',
            border: {
                radius: '4px',
                size: "small"
            },
            margin: {
                vertical: 'xsmall'
            },
            extend: ({ theme, showBorder }) => {
                return showBorder ? `border: 1px solid ${ normalizeColor(theme.global.colors['border'], theme) };` : '';
            }
        },
        elevation: {
            // Elevation values were derived from this Figma file.
            // https://www.figma.com/file/eZYR3dtWdb9U90QvJ7p3T9/HPE-Color-Styles?node-id=405%3A25
            // Naming in Figma file is strong/default/weak vs. Grommet t-shirt sizing.
            // As defined here, default is currently mapping to medium.
            light: {
                small: '0px 2px 4px #00000007;',
                medium: '0px 6px 12px #00000007;',
                large: '0px 0px 24px #00000022;',
            },
            dark: {
                small: '0px 2px 4px #0000003D;',
                medium: '0px 6px 12px #0000005C;',
                large: '0px 12px 24px #0000007A;',
            },
        },
    },
    layer: {
        background: {
            light: 'light-1',
            dark: 'dark-1'
        }
    },
    scale: 1,
    text: {
        xsmall: {
            size: '14px',
            height: '20px',
            maxWidth: '288px',
        },
        small: {
            size: '16px',
            height: '22px',
            maxWidth: '336px',
        },
        medium: {
            size: '18px',
            height: '24px',
            maxWidth: '384px',
        },
        large: {
            size: '22px',
            height: '26px',
            maxWidth: '432px',
        },
        xlarge: {
            size: '28px',
            height: '32px',
            maxWidth: '576px',
        },
        xxlarge: {
            size: '36px',
            height: '40px',
            maxWidth: '768px',
        },
        extend: ({ monospaced, uppercase, bold }) => {
            let result = '';
            result += (monospaced) ? 'font-family: monospace,monospace;' : '';
            result += (uppercase) ? 'text-transform: uppercase;' : '';
            return result;
        },
    },
    radioButton: {
        hover: {
            border: {
                color: 'brand'
            }
        }
    },
    paragraph: {
        font: {
            family: 'Calibre, sans-serif',
            weight: 400
        },
        small: {
            size: '16px',
            height: '22px',
            maxWidth: '336px',
        },
        medium: {
            size: '18px',
            height: '24px',
            maxWidth: '384px',
        },
        large: {
            size: '20px',
            height: '26px',
            maxWidth: '432px',
        },
        xlarge: {
            size: '24px',
            height: '32px',
            maxWidth: '576px',
        },
        xxlarge: {
            size: '32px',
            height: '40px',
            maxWidth: '768px',
        },
    },
    dateInput: {
        icon: {
            size: '16px',
        },
    },
    calendar: {
        heading: {
            level: '6',
        },
        small: {
            fontSize: '13.6px',
            lineHeight: 1.375,
            daySize: '27.43px',
        },
        medium: {
            fontSize: '18px',
            lineHeight: 1.41,
            daySize: '35px',
        },
        large: {
            fontSize: '18px',
            lineHeight: 1.45,
            daySize: '54.86px',
        },
        extend: ({sizeProp}) => {
            return `
                ${sizeProp === 'medium'? 'width: 250px;' : ''}
            `
        },
    },
    list: {
        item: {
            border: undefined,
        },
    },
    button: {
        default: {
            color: 'text',
            border: undefined,
            font: {
                weight: 500,
            },
            cursor: "pointer"
        },
        primary: {
            background: {
                color: 'cta',
                opacity: 1
            },
            border: undefined,
            font: {
                weight: 500,
            },
            color: 'black'
        },
        secondary: {
            border: {
                color: 'border',
                width: '1.5px',
            },
            background: {
                color: 'foreground',
                opacity: 1
            },
            font: {
                weight: 500
            },
            color: 'text'
        },
        option: {
            color: 'text',
            border: {
                radius: '0px',
            },
            font: {
                weight: 500,
            }
        },
        hover: {
            background: {
                color: 'hover'
            },
            primary: {
                background: {
                    color: 'cta-hover',
                    opacity: 1
                },
                color: 'black'
            },
            secondary: {
                background: 'hover'
            },
        },
        active: {
            background: {
                color: 'active',
            },
            color: 'text',
            secondary: {
                border: {
                    color: 'transparent',
                },
            },
            option: {
                background: {
                    color: 'active-background',
                },
            },
        },
        size: {
            small: {
                border: {
                    radius: '4px',
                },
                pad: {
                    vertical: '4px',
                    horizontal: '18px',
                }
            },
            medium: {
                border: {
                    radius: '4px',
                },
                pad: {
                    vertical: '6px',
                    horizontal: '18px',
                }
            },
            large: {
                border: {
                    radius: '6px',
                },
                pad: {
                    vertical: '8px',
                    horizontal: '24px',
                }
            }
        },
        border: {
            radius: '4px',
        },
        pad: {
            vertical: '4px',
            horizontal: '10px',
        },
        extend: (props) => {
            let additionalStyle = '';
            if (!!props.ctaColor) {
                additionalStyle += `
                    background-color: ${normalizeColor(theme.global.colors[props.ctaColor], props.theme)};
                    &:hover {
                        background-color: ${normalizeColor(theme.global.colors[props?.ctaHoverColor || props.ctaColor], props.theme)}${props?.ctaHoverColor ? '' : '88'};
                    }
                `;
            }
            if (!!props.ctaBorderColor) {
                additionalStyle +=  `
                    border: ${normalizeColor(theme.global.colors[props.ctaBorderColor], props.theme)} solid 1.5px;
                    &:hover {
                        border-color: ${normalizeColor(theme.global.colors[props.ctaBorderColor], props.theme)}88;
                    }
                `;
            }
            if (props.noBorderRadius) {
                additionalStyle += ` border-radius: 0 `;
            }
            return additionalStyle;
        },
    },
    dropButton: {
        size: {
            small: {
                border: {
                    radius: '4px',
                },
                pad: {
                    vertical: '4px',
                    horizontal: '18px',
                }
            },
            medium: {
                border: {
                    radius: '4px',
                },
                pad: {
                    vertical: '6px',
                    horizontal: '18px',
                }
            },
            large: {
                border: {
                    radius: '6px',
                },
                pad: {
                    vertical: '8px',
                    horizontal: '24px',
                }
            }
        },
    },
    checkBox: {
        border: {
            color: "border",
            width: "2px"
        },
        hover: {
            border: {
                color: "border"
            },
            background: {
                color: 'hover'
            }
        },
        focus: {
            outline: {
                color: "focus"
            }
        },
        toggle: {
            border: {
                color: 'border'
            },
            extend: ({ checked, theme }) => `
                border: 1px solid ${ theme.global.colors.border[theme.dark ? 'dark' : 'light'] };
              ${checked &&
                `background-color: ${theme.global.colors['status-ok'][theme.dark ? 'dark' : 'light']};`}
            `,
            knob: {
                extend: ({ theme }) => `
                    box-shadow: ${ theme.global.elevation[theme.dark ? 'dark' : 'light'].small };
                    border: 1px solid ${ theme.global.colors['neutral-6'][theme.dark ? 'dark' : 'light'] };
                    background-color: white !important; /* weird grommet issue where background-color is a weird accent color they have */
                    color: white;
              `,
            },
        },
        check: {
            radius: '2px',
            extend: ({ theme, checked, indeterminate }) => `
            box-shadow: none;
            background-color: ${
    checked || indeterminate
        ? theme.global.colors['lemon'][theme.dark ? 'dark' : 'light']
        : theme.global.colors.background[theme.dark ? 'dark' : 'light']
};`,
        },
        icon: {
            extend: ({ theme }) => `stroke-width: 2px;
            stroke: ${ theme.global.colors['neutral'][theme.dark ? 'dark' : 'light'] }`,
        },
        extend: ({ disabled, theme }) => `
            ${!disabled &&
            `:hover {
                background-color: ${theme.global.colors['background-contrast'][theme.dark ? 'dark' : 'light']};
            }`}
            font-weight: 400;
            width: auto;
            padding: ${theme.global.edgeSize.xsmall} ${theme.global.edgeSize.small};
        `,
    },
    tip: {
        content: {
            round: "xsmall",
            background: {
                color: "foreground",
                opacity: "0.95"
            },
            color: "text",
            elevation: 'none',
            border: { color: "border" }
        }
    },
    anchor: {
        color: 'text',
        textDecoration: 'underline',
        fontWeight: 400,
        hover: {
            textDecoration: 'underline'
        },
    },
    heading: {
        font: {
            family: 'Calibre, sans-serif'
        },
        level: {
            "1": {
                font: { weight: 500 },
                small: {
                    size: "18px",
                    height: "20px"
                },
                medium: {
                    size: "24px",
                    height: "26px"
                },
                large: {
                    size: "32px",
                    height: "34px"
                },
                xlarge: {
                    size: "38px",
                    height: "40px"
                },
            },
            "2": {
                font: { weight: 500 },
                small: {
                    size: "16px",
                    height: "20px"
                },
                medium: {
                    size: "24px",
                    height: "26px"
                },
                large: {
                    size: "28px",
                    height: "28px"
                },
                xlarge: {
                    size: "36px",
                    height: "36px"
                },
            },
        }
    },
    notification: {
        container: {
            pad: {
                vertical: 'small'
            },
            border: {
                color: 'border'
            }
        },
        critical: {
            icon: () => (
                <StatusIcon type="error" size="medium" />
            )
        },
        normal: {
            icon: () => (
                <StatusIcon type="ok" size="medium" />
            )
        },
        close: {
            icon: () => (
                <Box pad="xsmall">
                    <FontAwesomeIcon
                        SelectedIcon={IconClose}
                        size="xxxsmall"
                    />
                </Box>
            )
        },
        title: {
            weight: "normal"
        }
    },
    accordion: {
        panel: {
            border: {
                color: 'border',
                side: 'bottom',
            },
        },
        heading: {
            margin: { vertical: 'medium', horizontal: 'xsmall' },
        },
        hover: {
            heading: {
                color: undefined,
            },
        },
        border: undefined,
        icons: {
            color: 'text',
        },
    },
    select: {
        control: {
            extend: ({ disabled }) => `
                ${disabled &&
                `
                opacity: 0.3;
                input {
                    cursor: default;
                }`}
            `,
        },
        icons: {
            color: 'text',
        }
    },
    fileInput: {
        round: "xsmall",
        pad: "xsmall"
    },
    formField: {
        focus: {
            border: {
                color: 'focus'
            }
        },
        border: {
            color: "border",
            error: {
                color: {
                    dark: 'white',
                    light: 'status-critical',
                },
            },
            position: 'inner',
            side: 'all',
            size: 'small',
            style: 'solid',
        },
        content: {
            margin: { vertical: 'xsmall' },
            pad: 'small',
        },
        disabled: {
            background: {
                color: 'status-disabled',
                opacity: 'weak',
            },
            border: {
                color: 'border-weak',
            },
            label: {
                color: 'text-weak',
            },
        },
        error: {
            background: {
                color: 'validation-critical',
            },
            container: {
                gap: 'xsmall',
            },
            color: 'status-critical',
            margin: {
                bottom: 'xsmall',
                top: 'none',
                horizontal: 'none',
                start: 'xxsmall',
            },
            size: 'small',
            icon: false
        },
        help: {
            color: 'dark-3',
            margin: {
                start: '0',
            },
            size: 'small',
            icon: false
        },
        info: {
            size: 'xsmall',
            color: 'text-xweak',
            margin: {
                bottom: 'xsmall',
                top: 'none',
                horizontal: 'none',
                start: 'xxsmall',
            },
        },
        label: {
            requiredIndicator: true,
            margin: {
                top: 'xsmall',
                bottom: 'none',
                horizontal: 'none',
            },
            weight: 500,
            color: 'text-strong',
            size: 'small',
        },
        margin: 'none',
        round: '4px',
        extend: (props) => {
            if (props.className === 'signature') {
                return `
                    input {
                        font-family: 'Brush Script', 'Brush Script MT', script;
                        font-size: 28px;
                    }
                `;
            }
            if (props.className === 'small') {
                return `
                    input {
                        padding: 5px 10px;
                    }
                `;
            }
        },
    }
};

export default theme;