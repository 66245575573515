// for Menu. Measurement inem
export const CONDENSED_DESKTOP_MENU_WIDTH = 5.5;
export const FULL_DESKTOP_MENU_WIDTH = 11.5;
export const COLLAPSED_DESKTOP_SUBMENU_WIDTH = 1;
export const DESKTOP_SUBMENU_WIDTH = 14;
export const MOBILE_MENU_HEIGHT = 4;
export const DEFAULT_SIDE_PANEL_WIDTH = '16em';

// the widths of the brand logos
export const LOGO_SIZE_XSMALL = '100px';
export const LOGO_SIZE_SMALL = '120px';
export const LOGO_SIZE_MEDIUM_SMALL = "150px";
export const LOGO_SIZE_MEDIUM = "180px";

// column widths in data table
export const FIXED_WIDTH_COLUMN_X_SMALL = "75px";

// name card width
export const NAME_CARD_MIN_WIDTH = "250px";
export const INVOICE_CARD_MIN_WIDTH = "300px";
export const INVOICE_CARD_MAX_WIDTH = "340px";

// icon sizes
export const ICON_WIDTH_XXXSMALL = 12;
export const ICON_WIDTH_XXSMALL = 14;
export const ICON_WIDTH_XSMALL = 18;
export const ICON_WIDTH_SMALL = 20;
export const ICON_WIDTH_MEDIUM = 24;
export const ICON_WIDTH_LARGE = 30;
export const ICON_WIDTH_XLARGE = 40;
export const ICON_WIDTH_XXLARGE = 64;
export const ICON_WIDTH_DEFAULT = 16;

// page size
export const LETTER_SIZE_HEIGHT = "1050px";

// page header dropdown menus
export const DROPDOWN_MENU_DESKTOP_WIDTH = "340px";

// modal width
export const DIALOG_MODAL_WIDTH = "500px";

export const BILLING_SCHEDULE_COLUMN_WIDTH = ['auto', '100px', '170px', '100px'];

export const INVOICE_VERSION_DROP_WIDTH = "500px";

export const ILLUSTRATIONS_WIDTH = "130px";