export const trackEvent = ({ pageDef, element=null, action, payload={} }) => {
    if (typeof analytics === "undefined" || !pageDef) return;
    const {component, page} = pageDef;
    analytics.track(`${component} ${element ? `${element} ` : ''}${action}`, {page, ...payload});
}

export const trackClick = (props) => trackCustomEvent({...props, action: 'click_action'});
export const trackOpen = (props) => trackCustomEvent({...props, action: 'open_action'});
export const trackClose = (props) => trackCustomEvent({...props, action: 'close_action'});

// for components triggered by keys
export const trackCustomEvent = ({page, action, ...rest}) => {
    if (typeof analytics === "undefined") return;
    let path = page || window.location.pathname;
    try {
        analytics.track(action, {page: path, ...rest});
    } catch(e) {
        console.log("Segment Error", e);
    }
}

export const customTrackEvent = (eventName, payload={}) => {
    if (typeof analytics === "undefined" || !eventName) return;
    try {
        analytics.track(eventName, payload);
    } catch {
        console.log("Segment Error");
    }
}

export const pageEvent = (url) => {
    if (typeof analytics === "undefined") return;
    try{
        analytics.page(url);
    } catch{
        console.log("Segment Error");
    }
}

export const identifyUser = (user) => {
    if (typeof analytics === "undefined" || !user) return;
    try{
        const integrations = {
            ...(user.intercom_token && {
                Intercom: {
                    email: user.email,
                    user_hash: user.intercom_token
                }
            })
        };

        analytics.identify(user.id, {
            email: user.email
        }, {
            integrations: integrations
        });
    } catch{
        console.log("Segment Error");
    }
}