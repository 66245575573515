import React from "react"
import {useSession} from "next-auth/react";
import {useRouter} from "next/router";
import {filterLinksFromPermissions} from "../../utils/permissions";
import Honeybadger from "@honeybadger-io/js";

const AuthManager = ({isAuthRequired}) => {
    const router = useRouter();
    const {asPath} = router;
    const {data: session, status} = useSession({
        required: isAuthRequired,
        onUnauthenticated() {
            if (isAuthRequired) {
                return router.push({pathname: '/session/signin', query: {redirectPath: asPath}}, '/session/signin');
            }
        }
    })

    if (isAuthRequired) {
        if (status === "authenticated" && session) {
            Honeybadger.setContext({
                user: session?.user
            });

            //Is this a protected route?
            const [isValid, redirectPath] = validateProtectedRoute(session || {}, asPath);
            if (!isValid) router.push(redirectPath);
            return <></>;
        }

        if (status === "unauthenticated") {
            router.push('/session/signin');
            return (<></>);
        }
    } else {
        const [isValid, redirectPath] = validateRoute(asPath);
        if (status === "authenticated") {
            if (!isValid) {
                router.push(redirectPath);
                return <></>;
            }
        }
    }

    return <></>;
}

const validateProtectedRoute = ({user, company}, asPath) => {
    // If no company (which shouldn't happen, but in the case it does,
    // it should sign the user out to prevent stuck state)
    if (!company) return [false, '/session/signout'];

    // If sign up is complete -> Deal with Approved vs Not Approved
    if (company.sign_up_complete){
        if (!company?.seamless_activation_status) { // if seamless activation feature flag is off
            // if customer is not in seamless activation, and their account isn't approved yet
            if (company.company_application_status === "applied") {
                return [false, '/signup/pending'];
            }
            // check to see if allow into app
            if (/^\/(signup|session)/.test(asPath)) {
                return [false, '/'];
            } else {
                const permissions = user?.permissions;
                const links =
                    filterLinksFromPermissions(permissions);
                // redirect when trying to hit an asPath that
                // user does not have permission to
                if (
                    asPath !== "/account-details" &&
                    !links.some((link) =>
                        asPath.includes(link.href)
                    )
                ) {
                    return [false, links[0]?.href];
                }
            }
        } else { // if seamless activation feature flag is on
            // check to see if allow into app
            if (/^\/(signup|session)/.test(asPath)) {
                return [false, '/'];
            } else {
                const permissions = user?.permissions;
                const links =
                    filterLinksFromPermissions(permissions);
                // redirect when trying to hit an asPath that
                // user does not have permission to
                if (
                    asPath !== "/account-details" &&
                    !links.some((link) =>
                        asPath.includes(link.href)
                    )
                ) {
                    return [false, links[0]?.href];
                }
            }
        }
    } else { // if sign up not complete, set user back the the stag left off
        // If Shopify is linked, then skip /signup/shop and go to /signup/business
        if(company.has_shopify_linked && (asPath === '/signup/shop')){
            return [false, '/signup/business'];
        }

        // Always redirect to /signup/business unless
        // user was on /signup/shop (which makes it skippable)
        if((asPath !== "/signup/shop") && (asPath !== "/signup/business")){
            return [false, '/signup/business'];
        }
    }

    return [true];
};

const validateRoute = (asPath) => {
    /*
      If the user is going to a /session/* page redirect them to /
      If the user is going to /signup/blah page specifically redirect them to /
         exception /signup/pending
    */
    if (new RegExp('\/(session/(?!signin).*)').test(asPath) || (
        new RegExp(/(\/signup\/).+/).test(asPath) && !(new RegExp('\/(signup)(\/)(pending).*').test(asPath))
    )) {
        return [false, '/'];
    }
    //If the user is going to /signup?approved=true redirect them to /session/signin?source=shopify - Not sure why this
    if (/^\/(signup).*(approved=true|pending=true).*/.test(asPath)) {
        return [false, '/session/signin?source=shopify'];
    }

    return [true];
}

export default AuthManager;