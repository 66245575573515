import React from 'react';
import { trackClick } from '../utils/segment';
import {Button as GrommetButton} from 'grommet';
import { lowercase, snakeCase } from '../utils/string';

// just a wrapper around Grommet's Button, but with logging capability
const Button = ({
    onClick, 
    event, 
    ctaType,
    ...props
}) => {
    const {section: rawSection, element: rawElement, ...attrs} = event || {};
    const element = snakeCase(lowercase(rawElement || ''));
    const section = snakeCase(lowercase(rawSection || ''));
    const onButtonClick = e => {
        trackClick({
            section,
            element,
            ...attrs
        });
        onClick && onClick(e);
    }
    return (
        <GrommetButton 
            data-event-section={section || undefined} 
            data-event-element={element || undefined}
            onClick={onButtonClick} 
            {...(ctaType === "error" ? {
                ctaColor: "white",
                ctaBorderColor: "status-critical",
                ctaTextColor: "status-critical",
            } : {})}
            {...props} />
    );
} 

export default Button;