import React from "react";
import {Box, Text} from "grommet";

const Footer = ({ children, align="center", ...rest }) => (
    <Box gridArea="footer" {...rest} flex="grow">
        <Box align={align} margin={{ vertical: 'small' }} flex="grow">
            {children}
            <Text size="small" color="text-xweak">
        &copy;
                {new Date().getFullYear()}
                {' '}
        Streamlined Financial, Inc. All rights reserved.
            </Text>
        </Box>
    </Box>
);

export default Footer;
