import React from "react";
import {Grommet} from "grommet";
import {useDarkMode} from "next-dark-mode";
import theme from "../utils/theme";
import { useRouter } from 'next/router';

const ThemeProvider = ({ children }) => {
    const { darkModeActive } = useDarkMode();
    const { asPath } = useRouter();
    let mode = darkModeActive ? 'dark' : 'light';
    //Always show signup and payments in light mode.
    mode = new RegExp(/^\/(signup|payments).*/).test(asPath) ? 'light' : mode;

    return (
        <>
            <Grommet themeMode={mode} theme={theme} full className="grommet-wrapper">
                {children}
            </Grommet>
        </>
    );
};

export default ThemeProvider;
