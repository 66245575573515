import React, {Component} from "react";
import {Box, Heading, Paragraph, Text} from "grommet";

const NarrowHeading = ({heading, preheading, paragraph, paragraphProps={}, ...headingProps}) => (
    <Box>
        {preheading && (<Text size="small">{preheading}</Text>)}
        <Heading
            level={1}
            size="xlarge"
            alignSelf="start"
            textAlign="start"
            margin={{top: 'xsmall', bottom: 'xsmall'}}
            {...headingProps}
        >
            {heading}
        </Heading>
        {paragraph && (
            <Paragraph alignSelf="start" textAlign="start" size="large" {...paragraphProps}>
                {paragraph}
            </Paragraph>
        )}
    </Box>
);

export default NarrowHeading;
