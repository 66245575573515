export const isValueInObject = (obj, value, keys = undefined) => {
    const objKeys = keys ||  Object.keys(obj);
    const target = String(value).toLowerCase();
    for (const key of objKeys) {
        if ((String(obj[key]).toLowerCase()).includes(target)) return true;
    }
    return false;
};

export const isEmpty = (obj) => {return !obj};

export const isListEmpty = l => !l || l.length === 0;
export const isObject = (obj) => (obj && typeof obj === 'object' && !Array.isArray(obj) && obj !== null);
export const isObjectEmpty = (obj) => { return isEmpty(obj) || (isObject(obj) && Object.keys(obj).length === 0)};

export const findElementWithAttribute = (arr, key, value) => {
    if (!arr || (typeof arr !== 'object') || !key) return undefined;
    return arr.find(el => (el && el[key] === value));
}

export const filterAttributeInObject = (obj, predicate) =>
    Object.keys(obj)
        .filter( key => predicate(obj[key]) )
        .reduce( (res, key) => (res[key] = obj[key], res), {} );

export const doObjectsHaveSameValues = (x, y) => {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => doObjectsHaveSameValues(x[key], y[key]))
    ) : (x === y);
}

export const flattenObject = (obj) => {
    const flattened = {}
    Object.keys(obj).forEach((key) => {
        const value = obj[key]

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenObject(value))
        } else {
            flattened[key] = value
        }
    });

    return flattened
}

export const deepCopyObj = (obj) => JSON.parse(JSON.stringify(obj));

export const getDiffAttrsOfTwoObjs = (obj1, obj2) => {
    let diffKeys = [];
    if (!obj1 || !obj2) return;
    for (let key of Object.keys(obj1)){
        if (!obj2.hasOwnProperty(key)) {
            continue;
        }
        if (obj1[key] !== obj2[key]){
            diffKeys.push(key);
        }
    }
    return diffKeys;
}

// take in key[] and value[] and output {{key: key[0], value: value[0]}, ...}
//
// e.g. zipIntoObj(['a', 'b'], [1, 2]) => [{key: 'a', value: 1}, {key: 'b', value: 2}]
export const zipIntoObj = (keys, values) => {
    if (!keys || !values || (keys.length != values.length)) return undefined;
    return keys.map((key, index) => ({key, value: values[index]}));
}

// take in key[] and value[] and output {key[0]: value[0], ...}
//
// e.g. constructObjFromArray(['a', 'b'], [1, 2]) => {'a': 1, 'b', 2}
export const constructObjFromArray = (keys, values) => {
    if (!keys || !values || (keys.length != values.length)) return undefined;
    return Object.fromEntries(keys.map((k, i) => [k, values[i]]));;
}

export const removeUndefinedAttrFromObj = obj => Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});

// check if x and y have the same key value pairs with equal values
export const deepEqual = (x, y) => {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
}