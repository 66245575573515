import React from 'react';
import styled from 'styled-components';
import {Box} from 'grommet';

const HideScrollBar = styled(Box)`
    > ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;

export default HideScrollBar;