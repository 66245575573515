import React, { useEffect } from "react";
import withDarkMode from "next-dark-mode";
import {SessionProvider} from "next-auth/react";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import Script from 'next/script';
import "../style.css";
import Honeybadger from "@honeybadger-io/js";
import NotificationsProvider, {NotificationsContext} from "../components/NotificationsProvider";
import {DialogProvider} from "../components/DialogProvider";
import Error from "./_error";
import DataTablePreferenceProvider from "../components/DataTablePreferenceProvider";
import HideScrollBar from "../components/styledwrappers/HideScrollBar";
import ThemeProvider from "../components/ThemeProvider";
import AuthManager from "../components/hoc/AuthManager"
import * as snippet from '@segment/snippet';
import { pageEvent } from "../utils/segment";
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    developmentEnvironments: ['dev', 'test', 'development'], // will not report
    reportData: process.env.NODE_ENV === 'production', // report staging and production errors
    environment: process.env.NODE_ENV === 'production' ? (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ? 'staging' : 'production') : 'development',
    projectRoot: 'webpack://_N_E/./'
});

// This is handy for testing in dev. Will not be available in prod
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    window.Honeybadger = Honeybadger
}

// This is to track the previous url to ensure our back button (router.back()) won't navigate user
// outside of our app
const StoreBackUrl = () => {
    const router = useRouter();
    useEffect(() => {
        if (typeof globalThis == "undefined" || !globalThis) return;
        const storage = globalThis?.sessionStorage;
        if (!storage) return;
        storage.setItem("prevPath", storage.getItem("currentPath"));
        storage.setItem("currentPath", globalThis.location.pathname);
    }, [router.asPath]);
    return (<></>);
}

const renderSegmentSnippet = () => {
    const opts = {
        apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || '',
        // note: the page option only covers SSR tracking.
        // Router.events will cover the client side page changes
        page: false,
    };

    if (process.env.NODE_ENV === 'development') return snippet.max(opts);

    return snippet.min(opts);
};

Router.events.on('routeChangeComplete', (url) => {
    pageEvent(url);
});


function MyApp({ Component, pageProps }) {
    const { props, session } = pageProps || {};
    const { err } = props || {};

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const modifiedPageProps = { ...pageProps, err };

    Honeybadger.beforeNotify((notice) => {
        // filter out UnhandledRejection errors that have no information
        const ignoredMessages = [
            "Couldn't find Invoice",
            "ResizeObserver loop limit exceeded",
            "ResizeObserver loop completed with undelivered notifications"
        ];
        if (notice.name === 'window.onunhandledrejection') return false;
        if (['Error', 'window.onerror'].includes(notice.name) && ignoredMessages.includes(notice.message)) return false;
    });

    return err ?
        (<Error err={err} />):
        (
            // Refetch session every hour
            <SessionProvider session={session} refetchInterval={60*30}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0"
                    />
                </Head>
                <Script
                    id="segment-script"
                    dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
                />
                <AuthManager isAuthRequired={typeof Component.auth === 'undefined' || Component.auth}/>
                <StoreBackUrl />
                <NotificationsProvider>
                    <DialogProvider>
                        <DataTablePreferenceProvider>
                            <ThemeProvider>
                                <HideScrollBar>
                                    <NotificationsContext.Consumer>
                                        {(notificationProps) => (
                                            <Component {...modifiedPageProps} {...notificationProps}/>
                                        )}
                                    </NotificationsContext.Consumer>
                                </HideScrollBar>
                            </ThemeProvider>
                        </DataTablePreferenceProvider>
                    </DialogProvider>
                </NotificationsProvider>
            </SessionProvider>
        );
}

export default withDarkMode(MyApp);
