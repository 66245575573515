export const isWindows = () => {
    if (typeof window === "undefined" || typeof navigator === "undefined") return false;
    return navigator?.platform.toLowerCase().indexOf('win') > -1;
}

export const isMacOS = () => {
    if (typeof window === "undefined" || typeof navigator === "undefined") return false;
    return navigator?.platform.toLowerCase().indexOf('mac') > -1;
}

export const isSafari = () => {
    if (typeof window === "undefined" || typeof navigator === "undefined") return false;
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export const isMobile = (userAgent) => {
    return Boolean(userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ));
}