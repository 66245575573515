import React from "react";
import {
    faCircleExclamation as IconAlertCircle,
    faCircleCheck as IconCircleCheck,
    faCircle as SignBadgeCircle,
    faInfoCircle as IconInfoCircle
} from '@fortawesome/pro-light-svg-icons';
import FontAwesomeIcon from "./FontAwesomeIcon";

const StatusIcon = ({type, size="large", ...props}) => {
    let color;
    let SelectedIcon;
    switch (type) {
    case 'error':
        color = 'status-critical';
        SelectedIcon = IconAlertCircle;
        break;
    case 'warning':
        color = 'status-warning';
        SelectedIcon = IconAlertCircle;
        break;
    case 'info':
        color = 'text-info';
        SelectedIcon = IconInfoCircle;
        break;
    case 'not_completed':
        color = "border";
        SelectedIcon = SignBadgeCircle;
        break;
    case 'ok':
    default:
        color = 'status-ok';
        SelectedIcon = IconCircleCheck;
    }
    return (<FontAwesomeIcon SelectedIcon={SelectedIcon} color={color} size={size} {...props} />);
};

export default (StatusIcon);